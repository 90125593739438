import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { CircleX, FilePenLine } from "lucide-react";
import useHttp from "../hooks/useHttp";
import "./ViewDocument.css";
import { domain } from "../constants";

function ViewDocuments() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false); // State for edit modal
  const [documents, setDocuments] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentForEdit, setSelectedDocumentForEdit] = useState(null); // State to track document for edit
  const { postRequest } = useHttp();

  const fetchDocuments = async (criteriaNumber) => {
    try {
      const token = localStorage.getItem("token");
      const response = await postRequest(
        `/fetch/criteria/criteriacontents`,
        token,
        { criteriaNumber }
      );
      return response; // Assuming the API response is in JSON format
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error; // Propagate the error
    }
  };

  const recursiveFetch = async (criteriaNumber) => {
    try {
      const response = await fetchDocuments(criteriaNumber);

      if (response.success === false) {
        // Stop recursion if success is false
        return;
      }

      // Assuming documents are part of the response data structure
      const docs = response.message || [];

      // Update documents state with the new documents
      setDocuments((prevDocuments) => ({
        ...prevDocuments,
        [criteriaNumber]: docs.map((doc) => doc), // Assuming fileName is the key for document names
      }));

      // Recursive call with next criteria number
      await recursiveFetch(criteriaNumber + 1);
    } catch (error) {
      // Handle error (e.g., show error message)
      console.error("Recursive fetch error:", error);
      // You might want to handle the error state here
    }
  };

  useEffect(() => {
    // Start fetching documents recursively from criteria number 1
    recursiveFetch(1);
  }, []);

  const openModal = (documentPath) => {
    setSelectedDocument(documentPath);
    setModalIsOpen(true);
  };

  const openEditModal = (documentPath) => {
    setSelectedDocumentForEdit(documentPath); // Set selected document for edit
    setEditModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedDocument(null);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedDocumentForEdit(null);
  };

  return (
    <section className="text-gray-600 body-font p-1">
      {Object.keys(documents).length === 0 && (
        <div>
          <h2 className="text-xl font-bold my-3">Criteria List</h2>
          No Documents to Show.
        </div>
      )}
      {Object.keys(documents).map((criteriaNumber) => (
        <div key={criteriaNumber} className="mb-5">
          <h2 className="text-lg font-bold mb-2">Criteria {criteriaNumber}</h2>
          <div className="flex overflow-x-auto">
            {documents[criteriaNumber].length > 0 ? (
              documents[criteriaNumber].map((filePath, index) => (
                <div
                  key={index}
                  className="xl:max-w-[200px] xl:min-w-[200px] lg:max-w-[200px] lg:min-w-[200px] md:min-w-[200px] md:min-w-[200px] sm:max-w-[300px] sm:min-w-[300px] min-w-[250px] max-w-[250px]  rounded-xl overflow-hidden shadow-lg mb-4 mr-4"
                >
                  <img
                    className="w-full cursor-pointer"
                    src="/document.png"
                    alt="Document preview"
                    onClick={() => openModal(filePath)}
                  />
                  <div className="px-6 py-4">
                    <div className="font-bold text-md mb-2 flex justify-between items-center cursor-pointer">
                      {filePath.split("/").pop()}
                      <div
                        className="border p-2 rounded"
                        onClick={() => openEditModal(filePath)}
                      >
                        <FilePenLine size={25} />
                      </div>
                    </div>
                    <p className="text-gray-600 text-base text-sm cursor-pointer">
                      {filePath.split("/")[1]}{" "}
                      {/* Extracts "2.1" from "criteria2/2.1/itr1_preview.pdf" */}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No documents available for Criteria {criteriaNumber}.</p>
            )}
          </div>
        </div>
      ))}
      {/* Document Viewer Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Document Modal"
        className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 bg-gray-900"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl mx-auto p-6 h-full flex flex-col">
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700 self-end"
          >
            <CircleX />
          </button>
          <div className="w-full h-full min-w-full">
            {selectedDocument ? (
              <div className="flex flex-col items-center justify-center h-full">
                <iframe
                  src={`${domain}/uploads/Documents/${selectedDocument}`}
                  className="w-full h-full"
                  title="Document Viewer"
                ></iframe>
              </div>
            ) : (
              <p>No document selected.</p>
            )}
          </div>
        </div>
      </Modal>
      {/* Edit Modal */}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Document Modal"
        className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 bg-gray-900"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl mx-auto p-6 h-full flex flex-col">
          <button
            onClick={closeEditModal}
            className="text-gray-500 hover:text-gray-700 self-end"
          >
            <CircleX />
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            {selectedDocumentForEdit ? (
              <>
                <div className="flex m-5">
                  <h2 className="text-xl font-bold mb-4">Criteria Number</h2>
                  <h2 className="text-xl font-semibold mb-4 ml-5">
                    Criteria{" "}
                    {selectedDocumentForEdit
                      .split("/")[0]
                      .replace("criteria", "")}
                  </h2>
                </div>
                <div className="flex">
                  <h2 className="text-xl font-bold mb-4">File Name</h2>
                  <p className="text-xl font-semibold mb-4 ml-5">
                    {selectedDocumentForEdit.split("/").pop()}
                  </p>
                </div>
              </>
            ) : (
              <p>No document selected for edit.</p>
            )}
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default ViewDocuments;
