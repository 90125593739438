import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { SidebarItem } from "./Sidebar";
import { File, LayoutDashboard, User, UserPlus } from "lucide-react";
import { Outlet, useLocation } from "react-router-dom";

function Layout({ children }) {
  const location = useLocation();

  return (
    <div className="flex">
      <Sidebar>
        <SidebarItem
          icon={<LayoutDashboard size={20} />}
          text="Dashboard"
          route="/dashboard"
          isMenuSuperAdminItem={false}
          active={location.pathname === "/dashboard"}
        />

        <SidebarItem
          icon={<User size={20} />}
          text="Profile"
          route="/profile"
          active={location.pathname === "/profile/add-admin"}
          isMenuSuperAdminItem={true}
          dropdown={[
            {
              text: "Manage Admin",
              route: "/profile/add-admin",
              icon: <UserPlus size={16} />,
              isSubMenuSuperAdminItem: true,
              dropdownActive: location.pathname === "/profile/add-admin",
            },
          ]}
        />

        <SidebarItem
          icon={<File size={20} />}
          text="Documents"
          route="/documents/view-documents"
          active={
            location.pathname === "/documents/view-documents" ||
            location.pathname === "/documents/manage-documents" ||
            location.pathname === "/documents/status"
          }
          isMenuSuperAdminItem={false}
          dropdown={[
            {
              text: "View Documents",
              route: "/documents/view-documents",
              icon: <UserPlus size={16} />,
              isSubMenuSuperAdminItem: false,
              dropdownActive: location.pathname === "/documents/view-documents",
            },
            {
              text: "Manage Documents",
              route: "/documents/manage-documents",
              icon: <UserPlus size={16} />,
              isSubMenuSuperAdminItem: false,
              dropdownActive:
                location.pathname === "/documents/manage-documents",
            },
            {
              text: "Approve/ Reject Documents",
              route: "/documents/status",
              icon: <UserPlus size={16} />,
              isSubMenuSuperAdminItem: true,
              dropdownActive: location.pathname === "/documents/status",
            },
          ]}
        />
      </Sidebar>
      <main className="flex flex-col p-3 gap-3 w-full overflow-y-scroll max-h-screen">
        <Navbar />
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
