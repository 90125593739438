import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-blue-500">
      <nav className="relative px-4 py-4 flex justify-between items-center bg-white">
        <Link className="text-3xl font-bold leading-none" to="#">
          <img
            className="h-5 lg:h-7 rounded object-cover object-center lg:my-4 md:my-[-10%] my-[-10%]"
            src="/IQUADOC-logo.png"
            alt="IQUADOC Logo"
          />
        </Link>
        <div className="lg:hidden">
          <button
            className="navbar-burger flex items-center text-blue-600 p-3"
            onClick={toggleMenu}
          >
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <ul
          className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6 ${
            menuOpen ? "hidden" : "hidden"
          }`}
        >
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-gray-600 font-bold"
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-gray-600 font-bold"
              to="/about"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-gray-600 font-bold"
              to="/services"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              className="text-sm text-gray-400 hover:text-gray-600 font-bold"
              to="/contact"
            >
              Contact
            </Link>
          </li>
        </ul>
        <img
          className="h-14 hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6"
          src="/iem-logo.png"
          alt="IEM Logo"
        />
        <img
          className="h-14 hidden lg:inline-block py-2 px-6"
          src="/uem-logo.png"
          alt="UEM Logo"
        />
      </nav>

      {menuOpen && (
        <div className="navbar-menu relative z-50">
          <div
            className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"
            onClick={toggleMenu}
          ></div>
          <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-1/2 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <button className="navbar-close" onClick={toggleMenu}>
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <ul>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm text-gray-400 hover:text-gray-600 font-bold"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm text-gray-400 hover:text-gray-600 font-bold"
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm text-gray-400 hover:text-gray-600 font-bold"
                    to="/services"
                  >
                    Services
                  </Link>
                </li>
                <li className="mb-1">
                  <Link
                    className="block p-4 text-sm text-gray-400 hover:text-gray-600 font-bold"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-auto">
              <div className="pt-6 flex justify-between">
                <img
                  className="block pl-4 py-3 mb-3 leading-loose w-16"
                  src="/iem-logo.png"
                  alt="IEM Logo"
                />
                <img
                  className="block pr-4 py-3 mb-3.5 leading-loose w-16"
                  src="/uem-logo.png"
                  alt="UEM Logo"
                />
              </div>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
