import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../context/AuthProvider";
import useHttp from "../hooks/useHttp";
import Loader from "../components/Loader";
import { Eye, EyeOff, KeyRound, Mail } from "lucide-react";

const SignUp = () => {
  const [tempPass, setTempPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [email, setEmail] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showTempPassword, setShowTempPassword] = useState(false);
  const { setIsLoggedIn } = useLogin();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { postRequest } = useHttp();

  // old password, new password, email
  // http://iqacdocbackend-env.eba-vfuypuhm.ap-south-1.elasticbeanstalk.com/api/admin/first-signin

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await postRequest("/admin/first-signin", null, {
        email: email,
        password: tempPass,
        newPassword: newPass,
      });
      if (response.success) {
        setLoading(false);
        const { token } = response;
        setIsLoggedIn(true);
        localStorage.setItem("userRole", response.user.role);
        localStorage.setItem("token", token);
        navigate("/dashboard");
      } else {
        setLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <section className="min-h-screen flex items-stretch text-white lg:flex-row-reverse">
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
      <div
        className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/signup.png)`,
        }}
      ></div>
      <div
        className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0"
        style={{ backgroundColor: "#A8DDEB" }}
      >
        <div
          className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/signup.png)`,
          }}
        >
          <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
        </div>
        <div className="w-full py-6 z-20 justify-center">
          <div className="w-full px-24 z-10 py-6 object-center align-middle">
            <span className="flex title-font font-medium items-center justify-center text-gray-900 sm:mb-0">
              <img
                className="hidden lg:block md:h-10 xl:h-12 max-md:h-7 max-sm:h-6 rounded object-cover object-center sm:mb-0 mb-6 sm:mt-0 mt-4"
                src="/IQUADOC-logo.png"
                alt="IQUADOC-logo"
              />
            </span>
            <span className="flex title-font font-medium items-center justify-center text-gray-900 sm:mb-0">
              <img
                className="lg:hidden max-sm:h-6 md:h-12 max-md:h-7 rounded object-cover object-center sm:mb-0 mb-6 sm:mt-0 mt-4"
                src="/IQUADOC-white-logo.png"
                alt="IQUADOC-white-logo"
              />
            </span>
            {/* <p className="text-2xl my-4 text-gray-500">File management, sorted.</p> */}
          </div>
          <div className="flex justify-center">
            <div className="bg-[#1878B6] max-lg:bg-white h-1 w-1/3 max-sm:[-mt-2]"></div>
          </div>
          <h1 className="my-6 text-5xl max-md:text-4xl font-bold leading-tight text-black max-sm:text-white max-md:text-white max-lg:text-white">
            Register
          </h1>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <form
            className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
            onSubmit={handleSubmit}
          >
            <div className="pb-2 pt-4 relative">
              <div className="flex justify-start items-center">
                <Mail
                  className="absolute w-6 h-6 ml-3"
                  stroke="black"
                  fill="none"
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  className="block w-full pl-12 p-4 text-lg text-black rounded-sm bg-gray-100 border-2 border-black"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="pb-2 pt-4 relative">
              <div className="flex justify-start items-center">
                <KeyRound
                  className="absolute w-6 h-6 ml-3"
                  stroke="black"
                  fill="none"
                />
                <input
                  type={showTempPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="block w-full pl-12 p-4 text-lg text-black rounded-sm bg-gray-100 border-2 border-black"
                  //value={password}
                  onChange={(e) => setTempPass(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="ml-2 absolute right-4"
                  onClick={() => setShowTempPassword(!showTempPassword)}
                >
                  {showTempPassword ? (
                    <EyeOff className="w-6 h-6" stroke="black" fill="none" />
                  ) : (
                    <Eye className="w-6 h-6" stroke="black" fill="none" />
                  )}
                </button>
              </div>
            </div>
            <div className="pb-2 pt-4 relative">
              <div className="flex justify-start items-center">
                <KeyRound
                  className="absolute w-6 h-6 ml-3"
                  stroke="black"
                  fill="none"
                />
                <input
                  type={showTempPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Confirm Password"
                  className="block w-full pl-12 p-4 text-lg text-black rounded-sm bg-gray-100 border-2 border-black"
                  //value={password}
                  onChange={(e) => setNewPass(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="ml-2 absolute right-4"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <EyeOff className="w-6 h-6" stroke="black" fill="none" />
                  ) : (
                    <Eye className="w-6 h-6" stroke="black" fill="none" />
                  )}
                </button>
              </div>
            </div>
            <div className="px-4 pb-2 pt-4">
              <button
                className="uppercase flex justify-center gap-3 w-full p-4 text-lg rounded-full bg-gradient-to-r from-[#1878B6] to-blue-500 hover:from-blue-500 hover:to-[#1878B6] focus:outline-none"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Loader />
                    <p className="text-gray-900 text-bold">Loading...</p>
                  </>
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
