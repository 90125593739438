import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { HashLink } from "react-router-hash-link";
import { ArrowDownCircle } from "lucide-react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Header />
      <section className="text-gray-600 body-font sm:justify-center lg:h-full mt-14">
        <div className="flex md:flex-row flex-col items-center space-x-7">
          <div className="lg:w-3/4 lg:h-[50%] md:w-1/2 w-5/6 mb-10 md:mb-0 overflow-hidden">
            <img
              className="lg:w-[500%] lg:h-[500%] hidden md:block"
              alt="hero"
              src="landing-page.png"
            />
            <img
              className="lg:w-[500%] lg:h-[500%] lg:hidden md:hidden block"
              alt="hero"
              src="landing-page.png"
            />
          </div>
          <div className="items-center text-center lg:flex-grow sm:justify-center lg:w-3/4 md:w-1/2 flex flex-col lg:ml-[50px] md:items-start md:text-left w-[90%] md:pr-4 relative">
            <h1 className="title-font sm:text-4xl text-3xl mb-6 font-extrabold text-gray-900 lg:text-5xl xl:text-6xl lg:text-left md:text-left lg:w-full sm:w-full text-center md:text-4xl md:w-full">
              Managing documents
              <br className="hidden lg:inline-block" />
              <div className="lg:text-left md:text-left lg:w-full mt-2 md:w-full sm:w-full sm:text-center">
                can't be easier!
              </div>
            </h1>
            <div className="bg-[#1878B6] h-2 lg:w-[39%] md:w-[50%] w-[39%] md:mt-[-5px] flex mt-[-5px] space-y-7"></div>
            <p className="mb-8 mt-4 lg:w-[55%] leading-relaxed lg:text-justify md:text-justify text-center p-0">
              Manage your documents seamlessly using IQACDOC. Find your
              documents, add or remove, and do all the functionalities -
              all-in-one using IQACDOC!
            </p>
            <div className="flex justify-center md:justify-start lg:justify-start gap-7 lg:w-[50%] md:w-5/6 w-[80%] sm:w-[50%] items-center">
              <Link to="/signin">
                <button className="h-full w-48 inline-flex text-white bg-[#1878B6] border-0 py-2 px-6 focus:outline-none hover:bg-blue-500 justify-center text-lg">
                  Sign In
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <HashLink to={"/#card"}>
        <div className="flex items-center justify-center relative">
          <div className=" text-[#1878B6] absolute lg:bottom-10 lg:left-[46.5%] md:top-10 top-[40px]">
            <ArrowDownCircle size={35} />
          </div>
        </div>
      </HashLink>

      <section
        className="flex flex-col items-center text-gray-600 body-font lg:mt-40 md:mt-1 mt-0 pt-36"
        id="card"
      >
        <div className="lg:w-full md:w-full w-[90%] flex flex-col items-center text-center gap-5">
          <h1 className="title-font max-sm:text-3xl sm:text-4xl text-5xl mb-4 font-extrabold text-gray-900 lg:text-6xl ">
            Why Use IQACDOC?
          </h1>
          <p className="lg:w-[50%] mb-8 leading-relaxed text-center md:w-[50%]">
            Every recognised institution must establish an Internal Quality
            Assurance Cell (IQAC). As quality improvement is a never-ending
            process, the IQACDOC assures document management is never again a
            hassle.
          </p>
        </div>
        <div className="container px-5 py-24 mx-auto md:flex-row ">
          <div className="flex flex-wrap -m-4 justify-center lg:gap-0 md:gap-0 gap-10">
            <div className="xl:w-1/3 md:w-1/2 p-4 flex flex-col items-center justify-center">
              <div className="bg-blue-100 pb-6 pr-6 pl-6 rounded-lg w-80">
                <img
                  className="h-40 w-100 rounded w-50 object-cover object-center mb-6 lg:my-[-10%] md:my-[-10%] my-[-10%]"
                  src="1.jpg"
                  alt="content"
                />
                <h2 className="text-lg text-gray-900 font-medium title-font mt-10 mb-4">
                  Develop a system
                </h2>
                <p className="leading-relaxed text-base">
                  For conscious, consistent, and catalytic action to improve the
                  institution’s academic and administrative performance.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4 flex flex-col items-center justify-center">
              <div className="bg-blue-100 pb-6 pr-6 pl-6 rounded-lg w-80">
                <img
                  className=" w-120 rounded w-50 object-cover object-center mb-6 lg:my-[-10%] md:my-[-10%] my-[-10%]"
                  src="2.png"
                  alt="content"
                />
                <h2 className="text-lg text-gray-900 font-medium title-font mt-10 mb-4 ">
                  Manage Documents
                </h2>
                <p className="leading-relaxed text-base">
                  To ensure that academic and administrative activities are
                  completed in a timely, efficient, and progressive manner.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 md:w-1/2 p-4 flex flex-col items-center justify-center">
              <div className="bg-blue-100 pb-6 pr-6 pl-6 rounded-lg w-80">
                <img
                  className="h-40 w-100 rounded w-50 object-cover object-center mb-6 lg:my-[-10%] md:my-[-10%] my-[-10%]"
                  src="3.jpg"
                  alt="content"
                />
                <h2 className="text-lg text-gray-900 font-medium title-font mt-10 mb-4 ">
                  Institutional Quality Culture
                </h2>
                <p className="leading-relaxed text-base">
                  To establish and implement quality benchmarks/parameters for
                  the institution’s numerous academic and administrative
                  activities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
