import React, { useState, useEffect } from "react";
import { CheckCircle, CircleX, Eye, Trash2 } from "lucide-react";
import useHttp from "../hooks/useHttp";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import { domain, fileDomain } from "../constants"; // Ensure this import is correct
import "./DocumentStatus.css"; // Import the CSS file for animations

function DocumentStatus() {
  const { getRequest, postRequest } = useHttp();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [id, setId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [records, setRecords] = useState([]);

  const token = localStorage.getItem("token");

  const fetchFiles = async () => {
    try {
      const response = await getRequest("/file/tempview", token);
      if (response.success) {
        setRecords(
          response?.files?.map((file) => ({
            filename: (
              <div
                className={`flex gap-2 items-center cursor-pointer ${
                  file.isDeleteRequestedRow ? "delete-requested" : ""
                  // true ? "delete-requested" : ""
                }`}
                onClick={() => {
                  setSelectedDocument(
                    `criteria${file.criteria}/${file.subcriteria}/${file.filename}`
                  );
                  setModalVisible(true);
                }}
              >
                <h2
                  className={`font-bold ${
                    file.isDeleteRequestedRow && "hover:line-through decoration-red-300 decoration-2"
                  }`}
                >
                  {file.filename}
                </h2>
                <Eye size={20} />
                {file.isDeleteRequestedFile &&<Trash2 size={20} color="red" />}
                {/* {true && (
                  <Trash2 size={20} color="red" className="cursor-default" />
                )} */}
              </div>
            ),
            criteria: file.criteria,
            subcriteria: file.subcriteria,
            uploadedby: file.uploadedBy.name,
            email: file.uploadedBy.email,
            actions: (
              <div className="">
                {file.loading ? (
                  <img
                    src="/Stopwatch.gif"
                    alt="loader"
                    width={25}
                    height={25}
                    className="bg-none m-0 p-0"
                  />
                ) : (
                  <>
                    <CheckCircle
                      color="white"
                      className="inline-block mr-2 cursor-pointer bg-green-600 p-1 rounded-md shadow hover:bg-green-500"
                      size={24}
                      onClick={() => {
                        statusHandler(file._id, "accept");
                      }}
                    />
                    <CircleX
                      color="white"
                      className="inline-block mr-2 cursor-pointer bg-red-600 p-1 rounded-md shadow hover:bg-red-500"
                      size={24}
                      onClick={() => {
                        statusHandler(file._id, "reject");
                      }}
                    />
                  </>
                )}
              </div>
            ),
            loading: false, // Add loading state for each file action
          }))
        );
      } else {
        setRecords([]);
        console.error("Failed to fetch files:", response.message);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const statusHandler = async (id, choice) => {
    try {
      // Update loading state for the specific record
      const updatedRecords = records.map((r) =>
        r._id === id ? { ...r, loading: true } : r
      );
      setRecords(updatedRecords);

      if (choice === "accept") {
        const response = await postRequest(`/file/accept/${id}`, token);
        if (response.success) {
          toast.success(response.message);
          fetchFiles(); // Refetch files after accepting
        } else {
          toast.error(response.message);
        }
      } else {
        setId(id);
        setRejectModalVisible(true);
      }
    } catch (error) {
      console.error("Error handling status:", error);
      toast.error("Failed to perform action. Please try again later.");
    } finally {
      // Reset loading state after API call completes
      const updatedRecords = records.map((r) =>
        r._id === id ? { ...r, loading: false } : r
      );
      setRecords(updatedRecords);
    }
  };

  const rejectHandler = async () => {
    const response = await postRequest(`/file/reject/${id}`, token, {
      remarks,
    });
    if (response.success) {
      toast.success(response.message);
      setRejectModalVisible(false);
      fetchFiles(); // Refetch files after rejecting
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const columns = [
    { name: "Filename", selector: (row) => row.filename, sortable: true },
    { name: "Criteria", selector: (row) => row.criteria, sortable: true },
    { name: "Subcriteria", selector: (row) => row.subcriteria, sortable: true },
    { name: "Uploaded By", selector: (row) => row.uploadedby, sortable: true },
    { name: "Admin Email", selector: (row) => row.email, sortable: true },
    { name: "Actions", selector: (row) => row.actions, sortable: true },
  ];

  return (
    <>
      <div className="bg-white shadow-md rounded my-6 p-4">
        <Toaster position="top-center" reverseOrder={false} />
        <h2 className="text-xl font-bold mb-4">Documents List</h2>
        <div className="overflow-x-auto">
          <DataTable
            columns={columns}
            data={records}
            selectableRows
            pagination
            defaultSortFieldId={3}
          />
        </div>
      </div>

      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Document Modal"
        className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 bg-gray-900"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl mx-auto p-6 h-full flex flex-col">
          <button
            onClick={() => setModalVisible(false)}
            className="text-gray-500 hover:text-gray-700 self-end"
          >
            <CircleX />
          </button>
          <div className="w-full h-full min-w-full">
            {selectedDocument ? (
              <div className="flex flex-col items-center justify-center h-full p-3 border hide-scrollbar">
                <p className="font-bold underline text-lg">{`Filename : ${selectedDocument
                  ?.split("/")
                  ?.pop()}`}</p>
                {fileDomain ? (
                  <iframe
                    src={`${fileDomain}/temporarydata/Documents/${selectedDocument}`}
                    className="w-full h-full"
                    title="Document Viewer"
                  ></iframe>
                ) : (
                  <p>File domain is not defined.</p>
                )}
              </div>
            ) : (
              <p>No document selected.</p>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={rejectModalVisible}
        onRequestClose={() => setRejectModalVisible(false)}
        contentLabel="Rejection Remarks"
        className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 bg-gray-900"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl mx-auto p-6 h-auto flex flex-col">
          <button
            onClick={() => setRejectModalVisible(false)}
            className="text-gray-500 hover:text-gray-700 self-end"
          >
            <CircleX />
          </button>
          <div className="w-full h-full min-w-full text-center">
            <h2 className="text-xl font-bold mb-4">Rejection Remarks</h2>
            <textarea
              id="message"
              rows="5"
              placeholder="Reason why you're rejecting the document."
              className="my-2 py-2 px-4 rounded-md bg-white text-gray-900 w-full outline-none focus:ring-2 focus:ring-blue-600 border border-blue-400 border-2"
              onChange={(e) => setRemarks(e.target.value)}
            ></textarea>
            <button
              className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
              onClick={rejectHandler}
            >
              Reject Document
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DocumentStatus;
