// src/Guard/ProtectedRoute.js
import React from "react";
import { useLogin } from "../context/AuthProvider";
import SignIn from "../Pages/SignIn";
import { Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const { isLoggedIn } = useLogin();
  const token = localStorage.getItem("token");

  if (isLoggedIn === null) {
    // Return a loading indicator while checking authentication status
    return <div>Loading...</div>;
  }

  return isLoggedIn && token ? <Outlet /> : <SignIn />;
};

export default ProtectedRoute;
