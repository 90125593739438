import React, { useState } from "react";
import { CircleX } from "lucide-react";
import useHttp from "../hooks/useHttp";

function UploadForm({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    criteria: "",
    subcriteria: "",
    metadata: "",
    file: null,
  });
  const { postFileRequest } = useHttp();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      setFormData((prevData) => ({
        ...prevData,
        file: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const token = localStorage.getItem("token");

    if (!formData.file) {
      setError("Please select a file to upload.");
      setLoading(false);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("criteria", formData.criteria);
    formDataToSend.append("subcriteria", formData.subcriteria);
    formDataToSend.append("metadata", formData.metadata);
    formDataToSend.append("file", formData.file);

    try {
      const token = localStorage.getItem("token");
      const response = await postFileRequest(
        "/file/upload",
        token,
        formDataToSend
      );
      setLoading(false);
      if (response.success) {
        onClose();
      } else {
        console.error("Error response:", response.message);
        setError(response.message || "An unexpected error occurred");
      }
    } catch (error) {
      setLoading(false);
      setError("An error occurred while submitting the form");
      console.error("Error:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 w-[500px]">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-bold text-black">Add Document</h2>
          <button className="text-black" onClick={onClose}>
            <CircleX className="w-6 h-6" />
          </button>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Criteria
            </label>
            <input
              type="text"
              name="criteria"
              value={formData.criteria}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Subcriteria
            </label>
            <input
              type="text"
              name="subcriteria"
              value={formData.subcriteria}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              About the file
            </label>
            <input
              type="text"
              name="metadata"
              value={formData.metadata}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              File
            </label>
            <input
              type="file"
              name="file"
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UploadForm;
