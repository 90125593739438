import React, { useState } from "react";
import AdminForm from "../components/AdminForm";
import AdminTable from "../components/AdminTable";

function Profile() {
  const [showAdminForm, setShowAdminForm] = useState(false);
  const [AdminFormContent, setAdminFormContent] = useState("");

  const handleCloseAdminForm = () => {
    setShowAdminForm(false);
  };

  return (
    <>
      <div className="rounded-xl">
        <AdminTable />
        {/* 
        {showAdminForm && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
            <div className="bg-white p-4 rounded shadow-lg">
              <AdminForm
                onClose={handleCloseAdminForm}
                content={AdminFormContent}
              />
            </div>
          </div> */}
        {/* )} */}
      </div>
    </>
  );
}

export default Profile;
