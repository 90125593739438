// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

export const LoginContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null); // Initialize as null to indicate loading state

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Add logic here to validate the token if necessary
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
export default AuthProvider;
