// src/AppRouter.js
import React from "react";

import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import SignIn from "../Pages/SignIn";
import ProtectedRoute from "../Guard/ProtectedRoute";
import Layout from "../layout/Layout";
import Dashboard from "../Pages/Dashboard";
import ViewDocuments from "../Pages/ViewDocuments";
import ManageDocuments from "../Pages/ManageDocuments";
import DocumentStatus from "../Pages/DocumentStatus";
import Profile from "../Pages/Profile";
import SignUp from "../Pages/SignUp";
import AdminSignIn from "../Pages/AdminSignIn";

const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/admin/signin" element={<AdminSignIn />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile/add-admin" element={<Profile />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/documents/view-documents"
              element={<ViewDocuments />}
            />
            <Route
              path="/documents/view-documents"
              element={<ViewDocuments />}
            />
            <Route
              path="/documents/manage-documents"
              element={<ManageDocuments />}
            />
            <Route path="/documents/status" element={<DocumentStatus />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
