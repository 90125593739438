import React from "react";
import LoginProvider from "./context/AuthProvider";
import AppRouter from "./navigation/AppRouter";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <LoginProvider>
      <Router>
        <AppRouter />
      </Router>
    </LoginProvider>
  );
}

export default App;
