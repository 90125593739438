import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useHttp from "../hooks/useHttp";
import {
  CheckCircle,
  CirclePlus,
  FileUp,
  OctagonAlert,
  Trash2,
  UserPlus,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import AdminForm from "./AdminForm"; // Import AdminForm

function AdminTable() {
  const token = localStorage.getItem("token");
  const { getRequest, postRequest, deleteRequest } = useHttp();
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showAdminForm, setShowAdminForm] = useState(false); // State to show/hide AdminForm
  const [otp, setOtp] = useState("");
  const [adminData, setAdminData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);

  const otpHandler = async (admin) => {
    setLoading(true);
    try {
      const response = await postRequest("/send/otp", token);
      setAdminData(admin);
      if (response.success) {
        setShowDeleteForm(true);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteHandler = async () => {
    try {
      const data = await deleteRequest(
        "/superadmin/deleteadmin",
        { email: adminData.email, otp: otp.trim() },
        token
      );
      if (data.success) {
        toast.success("User Deleted Successfully");
        setOtp("");
        setShowDeleteForm(false);
        setRecords(records.filter((admin) => adminData.name !== admin.name));
      } else {
        setOtp("");
        setShowDeleteForm(false);
        toast.error(data.message);
      }
    } catch (error) {
      setOtp("");
      setShowDeleteForm(false);
      toast.error("Something went wrong. Try again in sometime");
    }
  };

  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await getRequest("/superadmin/getadmindata", token);
      setRecords(
        response?.admins?.map((admin, index) => ({
          id: index,
          name: admin.name,
          department: admin.department,
          contact: admin.contact,
          email: admin.email,
          isVerified: admin.isVerified ? (
            <CheckCircle size={20} color="green" />
          ) : (
            <OctagonAlert
              size={20}
              style={{ transform: "rotate(45deg)" }}
              color="yellow"
            />
          ),
          loading: false, // Adding individual loading state
        }))
      );
    } catch (error) {
      console.error("Error fetching admins:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const columns = [
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Email", selector: (row) => row.email, sortable: true },
    { name: "Contact", selector: (row) => row.contact, sortable: true },
    { name: "Department", selector: (row) => row.department, sortable: true },
    { name: "Status", selector: (row) => row.isVerified, sortable: true },
    {
      name: "Actions",
      selector: (row) => row.action,
      cell: (row) =>
        row.loading ? (
          <img
            src="/Stopwatch.gif"
            alt="loader"
            width={25}
            height={25}
            className="bg-none m-0 p-0"
          />
        ) : (
          <Trash2
            color="tomato"
            onClick={async () => {
              const updatedRecords = records.map((r) =>
                r.id === row.id ? { ...r, loading: true } : r
              );
              setRecords(updatedRecords);
              await otpHandler(row);
              setRecords((prevRecords) =>
                prevRecords.map((r) =>
                  r.id === row.id ? { ...r, loading: false } : r
                )
              );
            }}
          />
        ),
    },
  ];

  const filterHandler = (event) => {
    const term = event.target.value.toLowerCase();
    if (term === "") {
      fetchAdmins(); // Fetch all admins again to reset the records
    } else {
      const filteredData = records.filter(
        (record) =>
          record.name.toLowerCase().includes(term) ||
          record.email.toLowerCase().includes(term)
      );
      setRecords(filteredData);
    }
  };

  const handleAdminAdd = (newAdmin) => {
    setRecords((prevRecords) => [
      ...prevRecords,
      {
        id: prevRecords.length,
        name: newAdmin.name,
        department: newAdmin.department,
        contact: newAdmin.contact,
        email: newAdmin.email,
        isVerified: newAdmin.isVerified ? (
          <CheckCircle size={20} color="green" />
        ) : (
          <CirclePlus
            size={20}
            style={{ transform: "rotate(45deg)" }}
            color="red"
          />
        ),
        loading: false,
      },
    ]);
  };

  return (
    <>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="flex justify-between bg-white p-3">
          <div>
            <h2 className="text-xl font-bold">Admin List</h2>
          </div>
          <div
            className="flex gap-3 justify-center items-center bg-indigo-100 text-indigo-800 text-md group-hover:opacity-100 font-semibold group-hover:translate-x-0 p-2 rounded cursor-pointer mb-2 w-1/3"
            onClick={() => setShowAdminForm(true)}
          >
            Add Admin
            <UserPlus size={20} />
          </div>
          <div>
            <form action="#" method="GET" className="hidden lg:block lg:pl-2">
              <label htmlFor="topbar-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1 lg:w-96">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  onChange={filterHandler}
                  className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-9 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>
        </div>
        <DataTable columns={columns} data={records} selectableRows pagination />
      </div>
      {showAdminForm && (
        <AdminForm
          onClose={() => setShowAdminForm(false)}
          onAdminAdd={handleAdminAdd}
        />
      )}
      {showDeleteForm && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto lg:max-w-lg max-w-sm">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Are you sure you want to delete this admin?
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowDeleteForm(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <p className="my-1 text-blueGray-500 text-lg leading-relaxed">
                    We have sent you a 2FA code to your email, please enter it
                    to confirm deletion
                  </p>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="OTP"
                    className="bg-gray-100 w-full py-3 px-2 rounded border border-gray-400"
                  />
                </div>
                <div className="flex items-center justify-center gap-3 p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-emerald-500 background-transparent border border-gray-400 rounded px-6 py-3 font-bold uppercase text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowDeleteForm(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={deleteHandler}
                  >
                    Delete Admin
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}

export default AdminTable;
