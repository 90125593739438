import {
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  LogOut,
  ChevronUp,
} from "lucide-react";
import { useContext, createContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useScreenDetector } from "../hooks/useScreenDetector";
import { useLogin } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";

const SidebarContext = createContext();

export default function Sidebar({ children }) {
  const { isMobile } = useScreenDetector();
  const [expanded, setExpanded] = useState(!isMobile);
  const { setIsLoggedIn } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    setExpanded(!isMobile);
  }, [isMobile]);

  const handleSignOut = async () => {
    setIsLoggedIn(false);
    localStorage.clear();
    navigate("/");
  };

  const handleToggleSidebar = () => {
    setExpanded((curr) => {
      if (!curr && isMobile) {
        // Collapse all dropdowns when collapsing the sidebar on mobile
        document.querySelectorAll(".dropdown-content").forEach((el) => {
          el.style.display = "none";
        });
      }
      return !curr;
    });
  };

  return (
    <aside
      className={`h-screen overflow-hidden rounded-tr-3xl border-tr border-tr-4 and border-r-4 border-tr-indigo-100 border-r-indigo-100 ${
        expanded && "w-[300px]"
      }`}
    >
      <nav className="h-full flex flex-col bg-white border-r shadow-sm">
        <div
          className={`py-4 px-2 flex ${
            expanded ? "justify-between" : "justify-center"
          } items-center`}
        >
          <img
            src="/IQUADOC-logo.png"
            className={`overflow-hidden transition-all flex-1 p-3 ${
              expanded ? "w-16" : "hidden"
            }`}
            alt=""
          />
          <button
            onClick={handleToggleSidebar}
            className={`p-1.5 rounded-lg bg-gradient-to-tr from-indigo-200 to-indigo-100 border border-indigo-200 border-2 text-indigo-800 hover:bg-indigo-400 
            ${
              !expanded &&
              "bg-gradient-to-r from-indigo-200 to-indigo-100 border border-indigo-200 border-2 text-indigo-800 hover:bg-indigo-50 text-gray-600"
            }`}
          >
            {expanded ? <ChevronLeft /> : <ChevronRight />}
          </button>
        </div>

        <SidebarContext.Provider value={{ expanded, isMobile, setExpanded }}>
          <ul className="flex-1 px-3">{children}</ul>
        </SidebarContext.Provider>

        <div className="border-t flex p-3">
          <button
            className="flex justify-center items-center w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs bg-gradient-to-t from-red-500 to-red-400 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            type="button"
            onClick={() => handleSignOut()}
          >
            <LogOut size={20} />
          </button>
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
          `}
          >
            <div className="leading-4">
              <h4
                className="font-semibold cursor-pointer text-red-600"
                onClick={() => handleSignOut()}
              >
                Logout
              </h4>
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
}

export function SidebarItem({
  icon,
  text,
  alert,
  active,
  route,
  dropdown,
  isMenuSuperAdminItem,
}) {
  const { expanded, isMobile, setExpanded } = useContext(SidebarContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    if (!expanded) {
      // If the sidebar is collapsed, expand it first
      setExpanded(true);
    } else {
      setIsDropdownOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
  }, []);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [isMobile, expanded]);

  return (
    <div
      className={`relative ${
        (isMenuSuperAdminItem && userRole === "superadmin") ||
        userRole === "superadmin" ||
        !isMenuSuperAdminItem
          ? null
          : "hidden"
      }`}
    >
      <Link
        className={`
          relative flex items-center py-2 px-3 my-1
          font-medium rounded-md cursor-pointer
          transition-colors group
          ${
            active
              ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
              : "hover:bg-indigo-50 text-gray-600"
          }
        `}
        to={route}
        onClick={dropdown ? handleDropdownToggle : null}
        title={text} // Add title for tooltip
      >
        {icon}
        <span
          className={`overflow-hidden transition-all ${
            expanded ? "w-40 ml-3" : "w-0"
          }`}
        >
          {text}
        </span>
        {dropdown && expanded && (
          <>
            {isDropdownOpen ? (
              <ChevronUp
                size={20}
                className={`ml-auto ${
                  (isMenuSuperAdminItem && userRole === "superadmin") ||
                  userRole === "superadmin" ||
                  !isMenuSuperAdminItem
                    ? null
                    : "hidden"
                }`}
              />
            ) : (
              <ChevronDown
                size={20}
                className={`ml-auto ${
                  (isMenuSuperAdminItem && userRole === "superadmin") ||
                  userRole === "superadmin" ||
                  !isMenuSuperAdminItem
                    ? null
                    : "hidden"
                }`}
              />
            )}
          </>
        )}
        {alert && (
          <div
            className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
              expanded ? "" : "top-2"
            }`}
          />
        )}
        {!expanded && (
          <div
            className={`
            absolute left-full rounded-md px-2 py-1 ml-6
            bg-indigo-100 text-indigo-800 text-sm
            invisible opacity-20 -translate-x-3 transition-all
            group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
          `}
          >
            {text}
          </div>
        )}
      </Link>
      {isDropdownOpen && dropdown && (
        <div className="dropdown-content mt-1 ml-8">
          {dropdown.map((item, index) => (
            <Link
              key={index}
              to={item.route}
              className={`
                relative flex items-center py-2 px-3 my-1
                font-medium rounded-md cursor-pointer
                transition-colors group
                ${
                  item.dropdownActive
                    ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800"
                    : "hover:bg-indigo-50 text-gray-600"
                }
                ${
                  (item.isSubMenuSuperAdminItem && userRole === "superadmin") ||
                  userRole === "superadmin" ||
                  !item.isSubMenuSuperAdminItem
                    ? null
                    : "hidden"
                }
              `}
            >
              {item.icon}
              <span className="ml-2">{item.text}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
