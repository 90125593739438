import React, { useState } from "react";
import useHttp from "../hooks/useHttp";
import { CircleX } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";

function AdminForm({ onClose, onAdminAdd }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { postRequest } = useHttp();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    department: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const token = localStorage.getItem("token");
      const response = await postRequest(
        "/superadmin/addadmin",
        token,
        formData
      );
      setLoading(false);

      if (response && response.success) {
        toast.success(response.message);
        onAdminAdd(formData); // Call the callback function to update the admin list
        onClose();
      } else {
        toast.error(
          "Admin could not be added. Please try again after sometime."
        );
        setError(response?.message || "An unexpected error occurred");
        onClose();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Try again in sometime");
      setError("An error occurred while submitting the form");
      console.error("Error:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-auto bg-black bg-opacity-50">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-white rounded-lg p-8 w-[500px]">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-bold text-black">Add Admin Data</h2>
          <button className="text-black" onClick={onClose}>
            <CircleX className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Contact
            </label>
            <input
              type="tel"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Department
            </label>
            <input
              type="text"
              name="department"
              value={formData.department}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="px-4 py-2 mr-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminForm;
