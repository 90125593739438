import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { CircleX, FileUp, FilePlus2 } from "lucide-react";
import UploadForm from "../components/UploadForm";
import DataTable from "react-data-table-component";
import useHttp from "../hooks/useHttp";
import { useScreenDetector } from "../hooks/useScreenDetector";

function ManageDocuments() {
  const [files, setFiles] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [selectedDocumentForEdit, setSelectedDocumentForEdit] = useState(null);
  const { getRequest } = useHttp();
  const { isDesktop } = useScreenDetector();
  
  useEffect(() => {
    const fetchFiles = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await getRequest("/file/view", token);
        if (response.success) {
          console.log(response.files);
          setFiles(response.files);
          setRecords(
            response.files.map((file) => ({
              filename: file.filename,
              criteria: file.criteria,
              subcriteria: file.subcriteria,
              uploadedby: file.uploadedBy.name,
              email: file.uploadedBy.email,
              actions: (
                <div className="bg-amber-500 min-w-18 p-3 rounded-md hover:bg-amber-400 cursor-pointer flex items-center gap-1">
                  <FileUp
                    size={22}
                    className="inline-block cursor-pointer"
                    onClick={() => openEditModal(file)}
                    color="#fff"
                  />
                  {isDesktop && (
                    <span className="text-white font-bold ">
                      Update Document
                    </span>
                  )}
                </div>
              ),
            }))
          );
        } else {
          console.error("Failed to fetch files:", response.message);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };
    fetchFiles();
  }, []);

  const openEditModal = (document) => {
    setSelectedDocumentForEdit(document);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedDocumentForEdit(null);
  };

  const openUploadModal = () => {
    setUploadModalIsOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalIsOpen(false);
  };

  const columns = [
    {
      name: "Filename",
      selector: (row) => row.filename,
      sortable: true,
    },
    {
      name: "Criteria",
      selector: (row) => row.criteria,
      sortable: true,
    },
    {
      name: "Subcriteria",
      selector: (row) => row.subcriteria,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      sortable: true,
    },
  ];

  const [records, setRecords] = useState([]);

  const filterHandler = (event) => {
    const term = event.target.value.toLowerCase();
    if (term === "") {
      setRecords(
        files?.map((file) => ({
          filename: file.filename,
          criteria: file.criteria,
          subcriteria: file.subcriteria,
          uploadedby: file.uploadedBy.name,
          email: file.uploadedBy.email,
          actions: (
            <div className="bg-amber-500 min-w-24 p-3 rounded-md hover:bg-amber-400 cursor-pointer flex items-center gap-1">
              <FileUp
                size={22}
                className="inline-block cursor-pointer"
                onClick={() => openEditModal(file)}
                color="#fff"
              />
              <span className="text-white font-bold ">Update Document</span>
            </div>
          ),
        }))
      );
    } else {
      const filteredData = files.filter((file) =>
        file.filename.toLowerCase().includes(term)
      );
      setRecords(
        filteredData.map((file) => ({
          filename: file.filename,
          criteria: file.criteria,
          subcriteria: file.subcriteria,
          uploadedby: file.uploadedBy.name,
          email: file.uploadedBy.email,
          actions: (
            <div className="bg-amber-500 min-w-24 p-3 rounded-md hover:bg-amber-400 cursor-pointer flex items-center gap-1">
              <FileUp
                size={22}
                className="inline-block cursor-pointer"
                onClick={() => openEditModal(file)}
                color="#fff"
              />
              <span className="text-white font-bold ">Update Document</span>
            </div>
          ),
        }))
      );
    }
  };

  return (
    <div className="bg-white shadow-md rounded my-6 p-4">
      <div className="flex justify-between">
        <h2 className="text-xl font-bold mb-4">Documents List</h2>
        <div
          className="flex gap-3 justify-center items-center bg-indigo-100 text-indigo-800 text-md group-hover:opacity-100 font-semibold group-hover:translate-x-0 p-2 rounded cursor-pointer mb-2 w-1/3"
          onClick={openUploadModal}
        >
          Add/ Update Document
          <FilePlus2 size={20} />
        </div>
        <form action="#" method="GET" className="hidden lg:block lg:pl-2">
          <label htmlFor="topbar-search" className="sr-only">
            Search
          </label>
          <div className="relative mt-1 lg:w-96">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              onChange={filterHandler}
              className="bg-gray-50 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-9 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Search"
            />
          </div>
        </form>
      </div>
      <div className="overflow-x-auto">
        <DataTable
          columns={columns}
          data={records}
          selectableRows
          pagination
          defaultSortFieldId={3}
        />
      </div>

      {/* Edit Modal */}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Document Modal"
        className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 bg-gray-900"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl mx-auto p-6 h-full flex flex-col">
          <button
            onClick={closeEditModal}
            className="text-gray-500 hover:text-gray-700 self-end"
          >
            <CircleX />
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            {selectedDocumentForEdit ? (
              <>
                <div className="flex m-5">
                  <h2 className="text-xl font-bold mb-4">Criteria Number</h2>
                  <h2 className="text-xl font-semibold mb-4 ml-5">
                    Criteria {selectedDocumentForEdit.criteria}
                  </h2>
                </div>
                <div className="flex">
                  <h2 className="text-xl font-bold mb-4">File Name</h2>
                  <p className="text-xl font-semibold mb-4 ml-5">
                    {selectedDocumentForEdit.filename}
                  </p>
                </div>
              </>
            ) : (
              <p>No document selected for edit.</p>
            )}
          </div>
        </div>
      </Modal>

      {/* Upload Modal */}
      <Modal
        isOpen={uploadModalIsOpen}
        onRequestClose={closeUploadModal}
        contentLabel="Upload Form Modal"
        className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 bg-gray-900"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <UploadForm onClose={closeUploadModal} />
      </Modal>
    </div>
  );
}

export default ManageDocuments;
